<template>
  <v-dialog v-model="showModal" max-width="500px" persistent>
    <v-card>
      <v-card-title v-if="response.notification.body">
        <v-icon :color="response.data.status === 'ok' ? 'primary' : 'red'">
          {{ response.data.status === 'ok' ? 'mdi-check-circle' : 'mdi-alert-circle' }}
        </v-icon>&nbsp;
        {{ response.data.status === 'ok' ? 'Отлично!' : 'Произошла ошибка' }}
      </v-card-title>
      <v-card-text>{{ response.notification.body }}</v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="closeModal">Ок</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  computed: {
    showModal() {
      return !!this.$store.state.notifications.response.notification.body
    },
    response() {
      return this.$store.state.notifications.response || {}
    }
  },
  methods: {
    closeModal() {
      if(this.response.data?.status == 'ok') {
        this.$emit("close-modal");
      }
      this.$store.dispatch('notifications/hideNotification')
    },
    updateNotification(notificationData) {
      this.$store.dispatch('notifications/showNotification', notificationData);
    }
  },
  mounted() {
    navigator.serviceWorker.addEventListener('message', (event) => {
      console.log('message!', event.data)
      if (event.data.isFirebaseMessaging) {
        const notificationData = event.data;
        console.log('notificationData', notificationData)
        this.$emit("close-modal");
        // this.updateNotification(notificationData);
      }
    });
  },
}
</script>
